<template>
  <ProjectDetailsWrapper>
    <sdBreadcrumb :routes="routes" />
    <sdPageHeader v-if="schoolYear">
      <template #title>
        <div key="1" class="project-header">
          <sdHeading as="h2">ปีการศึกษา {{ schoolYear.year }}</sdHeading>
        </div>
      </template>
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1">
          <router-link :to="`/school/calendar/${schoolYearId}/details/evaluation`">
            แบบประเมินพัฒนาการประจำปีการศึกษา
          </router-link>
        </sdButton>
        <sdButton class="btn-add_new" size="default" type="primary" key="2" @click="onShowSchoolYearEditor">
          <router-link :to="`/school/calendar/${schoolYearId}/edit`">
            แก้ไขปฏิทินการศึกษา
          </router-link>
        </sdButton>
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="[10, 10]" v-if="alerts.length > 0" justify="center" :style="{ 'margin-bottom': '20px' }">
        <a-col v-for="(alert, index) in alerts" :key="index">
          <sdAlerts
            :outlined="false"
            :closable="false"
            :showIcon="true"
            :message="alert.message"
            :description="alert.description"
            :type="alert.type"
          />
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <!-- ความคืบหน้าการประเมิน -->
        <a-col :xxl="6" :xl="8" :md="24" :xs="24">
          <a-spin :spinning="isLoading">
            <div class="project-progress">
              <h3 v-if="selectedTerm">ความคืบหน้าการประเมิน เทอม {{ selectedTerm.termNo }}</h3>
              <a-progress :percent="currentProgress" :stroke-width="5" status="active" />
            </div>
          </a-spin>
          <sdCards headless>
            <div class="state-single">
              <div class="color-primary">
                <a to="#">
                  <sdFeatherIcons type="calendar" size="25" />
                </a>
              </div>
              <div>
                <sdHeading as="h5">{{ remainingBusinessDays }} </sdHeading>
                <p>วันที่เหลือในภาคเรียน</p>
              </div>
            </div>
            <div class="state-single">
              <div class="color-secondary">
                <a to="#">
                  <sdFeatherIcons type="users" size="25" />
                </a>
              </div>
              <div>
                <sdHeading as="h5">{{ students.length }}</sdHeading>
                <p>จำนวนนักเรียนทั้งหมด</p>
              </div>
            </div>
            <div class="state-single">
              <div class="color-success">
                <a to="#">
                  <sdFeatherIcons type="target" size="25" />
                </a>
              </div>
              <div>
                <sdHeading as="h5">{{ expectedConditions }}</sdHeading>
                <p>เป้าหมายการประเมิน(ครั้ง)</p>
              </div>
            </div>
            <div class="state-single">
              <div class="color-warning">
                <a to="#">
                  <sdFeatherIcons type="pie-chart" size="25" />
                </a>
              </div>
              <div>
                <sdHeading as="h5" v-if="records">{{ recordsInTerm.length }}</sdHeading>
                <p>ประเมินไปแล้ว(ครั้ง)</p>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :xxl="12" :xl="16" :md="24" :xs="24">
          <div class="about-project-wrapper">
            <sdCards>
              <a-spin :spinning="isLoading">
                <IncomeAndExpenses :schoolYearId="schoolYear.id" />
              </a-spin>
            </sdCards>
          </div>
        </a-col>
        <a-col :xxl="6" :xl="8" :md="24" :xs="24">
          <sdCards title="ห้องเรียนของคุณ">
            <template #button>
              <sdButton class="btn-addUser" outlined type="white" size="small" @click="goToClassrooms">
                <span>ทั้งหมด</span>
              </sdButton>
            </template>
            <div class="project-users" v-if="classrooms">
              <div
                class="porject-user-single"
                v-for="classroom in classrooms"
                :key="classroom.id"
                :style="{ cursor: 'pointer' }"
                @click="goToClassroomInfo(classroom.id)"
              >
                <div>
                  <a-avatar
                    shape="square"
                    size="large"
                    :style="{
                      color: 'rgba(95,99,242,1)',
                      backgroundColor: 'rgba(95,99,242,0.1)',
                      verticalAlign: 'middle',
                    }"
                  >
                    {{ classroom.roomNo }}
                  </a-avatar>
                </div>
                <div>
                  <sdHeading as="h5">{{ classroom.title }}</sdHeading>
                  <p>นักเรียน {{ classroom.studentInfos.length }} คน</p>
                </div>
              </div>
            </div>
          </sdCards>
          <sdCards title="นักเรียนทั้งหมด">
            <div class="state-single">
              <div class="color-primary">
                <a to="#">
                  <sdFeatherIcons type="users" size="25" />
                </a>
              </div>
              <div>
                <sdHeading as="h5">{{ maleStudents }} </sdHeading>
                <p>จำนวนนักเรียนชาย</p>
              </div>
            </div>
            <div class="state-single">
              <div class="color-secondary">
                <a to="#">
                  <sdFeatherIcons type="users" size="25" />
                </a>
              </div>
              <div>
                <sdHeading as="h5">{{ femaleStudents }}</sdHeading>
                <p>จำนวนนักเรียนหญิง</p>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :xxl="12" :xl="16" :md="24" :xs="24">
          <a-spin :spinning="isLoading">
            <TaskLists>
              <sdCards>
                <template #title>
                  <nav>
                    <!-- <router-link :to="`${matched[1].path.split('/:')[0]}/${params.id}/tasklist`">Task List</router-link>
                  &nbsp; &nbsp; -->
                    <router-link :to="{ name: 'schoolyear-activities' }">กิจกรรม</router-link>
                  </nav>
                </template>
                <router-view name="child"></router-view>
              </sdCards>
            </TaskLists>
          </a-spin>
        </a-col>
      </a-row>
    </Main>
  </ProjectDetailsWrapper>
</template>

<script>
import { ProjectDetailsWrapper, TaskLists } from './style';
import FileListCard from './overview/FileListCard';
import { expectedEvaluationConditions } from '@/utility/dashboardFormatter';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment-business-days';

import IncomeAndExpenses from './overview/IncomeAndExpenses.vue';

const getRemainingDays = (term, holidays) => {
  const startAt = moment();
  const endAt = moment(term.endDate);
  const diff = endAt.businessDiff(startAt, true);

  const count = diff - holidays.length;
  if (count < 0) {
    return 0;
  }
  return count;
};

const getBusinessDays = (term, holidays) => {
  const startAt = moment(term.startDate);
  const endAt = moment(term.endDate);
  const diff = endAt.businessDiff(startAt);
  return diff - holidays.length;
};

const getCurrentTerm = schoolYear => {
  const terms = schoolYear.terms.sort((a, b) => {
    return a.termNo < b.termNo;
  });

  const found = terms.find(term => {
    const startDate = moment(term.startDate);
    const endDate = moment(term.endDate);
    const today = moment();
    return today.isBetween(startDate, endDate, undefined, '[]');
  });

  if (!found) {
    return terms[terms.length - 1];
  }

  return found;
};

const SchoolYearDetails = {
  name: 'SchoolYearDetails',
  components: { ProjectDetailsWrapper, TaskLists, Main, FileListCard, IncomeAndExpenses },
  props: {
    schoolYearId: Number,
  },
  setup(props) {
    const { state, getters, dispatch } = useStore();
    const schoolYears = computed(() => state.schoolYear.data);
    const schoolYear = computed(() => schoolYears.value.find(schoolYear => schoolYear.id == props.schoolYearId));
    const classrooms = computed(() => state.classroom.classrooms);
    const students = computed(() => state.students.data);
    const records = computed(() => getters.getCurrentRecords());
    const conditions = computed(() => state.evaluateForm.conditionLibrary);

    const maleStudents = computed(
      () => students.value.filter(student => student.gender.toLowerCase() == 'male').length,
    );
    const femaleStudents = computed(
      () => students.value.filter(student => student.gender.toLowerCase() == 'female').length,
    );

    const schoolYearEvaluationProgress = computed(() => state.evaluateForm.currentSchoolYearProgress);
    const recordsInTerm = computed(() =>
      records.value.filter(record => {
        if (!selectedTerm.value) return [];
        return record.schoolTermId == selectedTerm.value.id;
      }),
    );

    const alerts = computed(() => {
      if (!schoolYear.value) return [];

      let alerts = [];

      if (!schoolYear.value.holidays || schoolYear.value.holidays.length <= 0) {
        alerts.push({
          type: 'warning',
          message: 'ไม่พบ "วันหยุด" ในปฏิทินการศึกษา',
          description: 'คุณสามารถเข้าไปเพิ่มวันหยุดได้ที่ "แก้ไขปฏิทินการศึกษา" เพื่อความถูกต้องในการคำนวณวันมาเรียน',
        });
      }
      return alerts;
    });

    const routes = computed(() => {
      return [
        {
          path: '',
          breadcrumbName: 'หน้าแรก',
        },
        {
          path: '/school/calendar/grid',
          breadcrumbName: 'ปฏิทินการศึกษา',
        },
        {
          path: `/school/calendar/${schoolYear.value.id}/details/activities`,
          breadcrumbName: `ปีการศึกษา ${schoolYear.value.year}`,
        },
      ];
    });

    const selectedTerm = ref(null);
    const currentProgress = ref(null);
    const totalBusinessDays = ref(null);
    const remainingBusinessDays = ref(null);
    const expectedConditions = ref(null);
    const isLoading = ref(false);

    selectedTerm.value = getCurrentTerm(schoolYear.value);
    totalBusinessDays.value = getBusinessDays(selectedTerm.value, []);
    remainingBusinessDays.value = getRemainingDays(selectedTerm.value, []);

    const { params, matched } = useRoute();
    const router = useRouter();

    const updateProgress = progresses => {
      if (!progresses) return;
      if (!selectedTerm.value) return;

      const termId = selectedTerm.value.id;
      const found = progresses.find(item => item.termId == termId);

      if (!found) {
        currentProgress.value = null;
      } else {
        currentProgress.value = found.percentage;
      }
    };

    const goToClassrooms = () => {
      router.push('/classroom');
    };

    const goToClassroomInfo = classroomId => {
      router.push(`/schoolYears/${props.schoolYearId}/classrooms/${classroomId}`);
    };

    const onShowSchoolYearEditor = () => {
      console.log('show school year editor');
    };

    onMounted(async () => {
      router.push({ name: 'schoolyear-activities' });
      isLoading.value = true;

      dispatch('filterSinglePage', parseInt(params.id, 10));
      dispatch('selectSchoolYear', schoolYear);

      if (!schoolYearEvaluationProgress.value) {
        dispatch('getSchoolYearEvaluationProgress', props.schoolYearId);
      }

      await dispatch('getAllClassrooms', { schoolYearId: props.schoolYearId });
      dispatch('updateStudentTable', classrooms.value);

      if (!conditions.value) {
        await dispatch('getAllTopicList', schoolYear.value.standardYear);
      }

      if (!records.value) {
        await dispatch('getClassroomDevelopmentRecords', {
          schoolYearId: schoolYear.value.id,
        });
      }

      expectedConditions.value = expectedEvaluationConditions(conditions.value, classrooms.value);

      updateProgress(schoolYearEvaluationProgress.value);
      isLoading.value = false;
    });

    watch(
      () => schoolYearEvaluationProgress.value,
      progresses => {
        updateProgress(progresses);
      },
    );

    return {
      routes,
      params,
      matched,
      schoolYear,
      selectedTerm,
      schoolYearEvaluationProgress,
      currentProgress,
      totalBusinessDays,
      remainingBusinessDays,
      expectedConditions,
      classrooms,
      students,
      records,
      recordsInTerm,
      goToClassrooms,
      goToClassroomInfo,
      onShowSchoolYearEditor,
      maleStudents,
      femaleStudents,
      isLoading,
      alerts,
    };
  },
};

export default SchoolYearDetails;
</script>
