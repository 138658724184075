<template>
  <IncomeExpenseWrapper>
    <sdCards v-if="attendanceData !== null">
      <template #title>
        <div>
          อัตราการมาเรียน <span>{{ attendanceDateString.startDate }} - {{ attendanceDateString.endDate }}</span>
        </div>
      </template>
      <template #button>
        <div class="card-nav">
          <ul>
            <li :class="incomeFlowActive === 'day' ? 'active' : 'regular'">
              <router-link @click="() => handleActiveChangeIncome('day')" to="#">
                สัปดาห์
              </router-link>
            </li>
          </ul>
        </div>
      </template>

      <div v-if="isIcLoading" class="sd-spin">
        <a-spin />
      </div>

      <a-row v-else :gutter="25">
        <a-col :xs="24">
          <sdChartContainer class="parentContainer">
            <Chart
              type="bar"
              class="incomeExpanse"
              :labels="attendanceData.labels"
              :datasets="attendanceDataset"
              :height="90"
              :style="{
                marginBottom: '0',
              }"
              :options="{
                maintainAspectRatio: true,
                responsive: true,
                legend: {
                  display: false,
                  position: 'bottom',
                  labels: {
                    boxWidth: 6,
                    display: true,
                    usePointStyle: true,
                  },
                  align: 'start',
                },
                layout: {
                  padding: {
                    left: '0',
                    right: 0,
                    top: 0,
                    bottom: '0',
                  },
                },
                ...toolTips,
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: '#e5e9f2',
                        borderDash: [3, 3],
                        zeroLineColor: '#e5e9f2',
                        zeroLineWidth: 1,
                        zeroLineBorderDash: [3, 3],
                      },
                      ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49',
                        max: Math.max(...attendanceData.present),
                        stepSize: Math.max(...attendanceData.present) / 5,
                        callback(label) {
                          return `${label}`;
                        },
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: true,
                        zeroLineWidth: 2,
                        zeroLineColor: '#fff',
                        color: 'transparent',
                        z: 1,
                      },
                      ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49',
                      },
                    },
                  ],
                },
              }"
            />
          </sdChartContainer>
          <ul v-if="incomeDataset" class="chart-dataIndicator">
            <li
              v-for="(item, index) in incomeDataset"
              :key="index + 1"
              :style="{ display: 'inline-flex', alignItems: 'center' }"
            >
              <span
                :style="{
                  width: '10px',
                  height: '10px',
                  display: 'flex',
                  backgroundColor: item.hoverBackgroundColor,
                  borderRadius: '50%',
                  margin: '0px 5px',
                }"
              />
              {{ item.label }}
            </li>
          </ul>
        </a-col>
      </a-row>
    </sdCards>
  </IncomeExpenseWrapper>
</template>
<script>
import { ExList, IncomeExpenseWrapper } from '../style';
import Chart from '@/components/utilities/chartjs';
import { customTooltips } from '@/components/utilities/utilities';
import { computed, onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

const getAttendanceDate = mode => {
  const unit = mode == 'day' ? 'week' : 'month';
  const endDate = moment()
    .endOf(unit)
    .format('YYYY-MM-DD');
  const startDate = moment()
    .startOf(unit)
    .format('YYYY-MM-DD');
  return {
    startDate,
    endDate,
  };
};

const getAttendanceDateString = mode => {
  const unit = mode == 'day' ? 'week' : 'month';
  const endDate = moment()
    .endOf(unit)
    .format('D MMMM YYYY');
  const startDate = moment()
    .startOf(unit)
    .format('D MMMM YYYY');
  return {
    startDate,
    endDate,
  };
};

const IncomeAndExpenses = {
  name: 'IncomeAndExpenses',
  components: { ExList, IncomeExpenseWrapper, Chart },
  props: { schoolYearId: Number },
  setup(props) {
    const { state, dispatch } = useStore();
    const incomeFlowActive = ref('day');
    const attendanceDateString = reactive(getAttendanceDateString(incomeFlowActive.value));

    onMounted(async () => {
      dispatch('incomeGetData');
      const attendaceDate = getAttendanceDate(incomeFlowActive.value);
      dispatch('getAttendanceSevenDaysSummary', {
        schoolYearId: props.schoolYearId,
        startDate: attendaceDate.startDate,
        endDate: attendaceDate.endDate,
        unit: incomeFlowActive.value,
      });
    });
    const handleActiveChangeIncome = async value => {
      console.log({ value });
      incomeFlowActive.value = value;
      const attendaceDate = getAttendanceDate(incomeFlowActive.value);
      dispatch('getAttendanceSevenDaysSummary', {
        schoolYearId: props.schoolYearId,
        startDate: attendaceDate.startDate,
        endDate: attendaceDate.endDate,
        unit: incomeFlowActive.value,
      });
    };
    const incomeState = computed(() => state.chartContent.incomeData);
    const isIcLoading = computed(() => state.chartContent.icLoading);
    const attendanceData = computed(() => state.attendance.dashboardData);
    const incomeDataset = computed(() => [
      {
        data: state.chartContent.incomeData.total[1],
        backgroundColor: '#5F63F250',
        hoverBackgroundColor: '#5F63F2',
        label: 'มาเรียน',
        barPercentage: 0.6,
      },
      {
        data: state.chartContent.incomeData.sale[1],
        backgroundColor: '#FF69A550',
        hoverBackgroundColor: '#FF69A5',
        label: 'ไม่มาเรียน',
        barPercentage: 0.6,
      },
    ]);

    const attendanceDataset = computed(() => [
      {
        data: state.attendance.dashboardData.present,
        backgroundColor: '#5F63F250',
        hoverBackgroundColor: '#5F63F2',
        label: 'มาเรียน',
        barPercentage: 0.6,
      },
      {
        data: state.attendance.dashboardData.absent,
        backgroundColor: '#FF69A550',
        hoverBackgroundColor: '#FF69A5',
        label: 'ไม่มาเรียน',
        barPercentage: 0.6,
      },
    ]);

    return {
      incomeState,
      isIcLoading,
      incomeFlowActive,
      incomeDataset,
      attendanceData,
      attendanceDataset,
      attendanceDateString,
      handleActiveChangeIncome,
      toolTips: {
        tooltips: {
          mode: 'label',
          intersect: false,
          position: 'average',
          enabled: false,
          custom: customTooltips,
          callbacks: {
            label(t, d) {
              const dstLabel = d.datasets[t.datasetIndex].label;
              const { yLabel } = t;
              return `<span class="chart-data">${yLabel}</span> <span class="data-label">${dstLabel}</span>`;
            },
            labelColor(tooltipItem, chart) {
              const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
              return {
                backgroundColor: dataset.hoverBackgroundColor,
                borderColor: 'transparent',
                usePointStyle: true,
              };
            },
          },
        },
      },
    };
  },
};

export default IncomeAndExpenses;
</script>
