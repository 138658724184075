const expectedEvaluationConditions = (conditions, classrooms) => {
  const expectedPerClassrooms = classrooms.map(classroom => {
    const { gradeLevel, studentInfos } = classroom;
    const expectedConditions = conditions.filter(cond => cond.gradeLevel == gradeLevel);
    return studentInfos.length * expectedConditions.length * 2;
  });
  return expectedPerClassrooms.reduce((acc, expConds) => {
    acc = acc + expConds;
    return acc;
  }, 0);
};

export { expectedEvaluationConditions };
